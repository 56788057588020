import "core-js/modules/es.array.push.js";
import { mapGetters, mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
import AddressFilterDialog from '@/components/crm/addresses/AddressFilterDialog.vue';
import AddAddressDialog from '@/components/crm/addresses/addAddress/AddAddressDialog.vue';
export default {
  components: {
    AddressFilterDialog,
    AddAddressDialog
  },
  data: () => ({
    fullTextSearchUrl: '/v1/crm/addresses/search/fullText',
    fullTextSearchInput: '',
    dialog: false,
    filterSet: false,
    searchSuccessful: false,
    results: [],
    controller: undefined,
    loading: false,
    searchStartedOnce: false,
    headers: [{
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name'
    }, {
      text: 'Straße',
      value: 'street'
    }, {
      text: 'PLZ',
      value: 'postcode'
    }, {
      text: 'Ort',
      value: 'city'
    }],
    date: '1999-01-01',
    isLoading: false
  }),
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter'])
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true;
      }
      return false;
    },
    initiateSearchUI() {
      this.loading = true;
      this.results = [];
    },
    searchFullText(query) {
      if (query == undefined) return;
      this.initiateSearchUI();
      //Check if there are any previous pending requests
      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      this.controller = new AbortController();
      this.isLoading = true;
      addressService.getSearchResults(this.fullTextSearchUrl, query, this.controller).then(response => {
        this.results = response;
        this.searchSuccessful = true;
        this.loading = false;
        this.isLoading = false;
      }).catch(err => {
        console.log('error searching address: ' + err);
        this.isLoading = false;
      });
    },
    searchTrigger() {
      if (this.fullTextSearchInput != null && this.fullTextSearchInput != '' && String(this.fullTextSearchInput).length > 2) {
        this.searchStartedOnce = true;
        this.$router.push({
          query: {
            q: this.fullTextSearchInput
          }
        });
        this.searchFullText(this.fullTextSearchInput);
      } else {
        this.removeQueryParam();
      }
    },
    setResults(value) {
      this.results = value;
    },
    removeQueryParam() {
      this.$router.replace({
        query: {}
      }).catch(() => {});
    },
    openAddress(lfdnr) {
      this.$router.push({
        name: 'crmAddress',
        params: {
          lfdnr: lfdnr
        }
      });
    },
    openAddressDataTable(value) {
      this.openAddress(value.lfdnr);
    },
    addressType(item) {
      return addressService.getAddressTypeDetailed(item.addressTypes[0]);
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({
        behavior: 'smooth'
      });
    },
    scrollToResults() {
      const element = this.$refs['resultsTable'];
      if (element) {
        this.$nextTick(() => {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    },
    twoCharactersValidation(value) {
      return this.filtersActive || !!value && String(value).length > 2 || 'Bitte geben Sie mehr als zwei Zeichen ein';
    }
  },
  watch: {
    // Workaround for clearing validation when opening filter
    filtersActive() {
      if (this.filtersActive) {
        this.fullTextSearchInput = ' ';
        this.$nextTick(() => {
          this.fullTextSearchInput = '';
        });
      }
    }
  },
  created() {
    window.addEventListener('keydown', event => {
      if (event.key == 'Enter') this.searchTrigger();
    });
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.fullTextSearchInput = this.$route.query['q'];
      this.$store.dispatch('unsetFilterActive');
      this.searchTrigger();
    }
  }
};