import { mapGetters, mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
import LBADialog from '@/components/ui/dialogs/LBADialog.vue';
export default {
  components: {
    LBAButton,
    LBADialog
  },
  data() {
    return {
      name: null,
      strasse: null,
      plz: null,
      ort: null,
      adrArt: null,
      customerGroup: null,
      kdBranche: null,
      allAddressTypes: [],
      branchen: [],
      customerGroups: [],
      filtersActiveIndex: 1,
      filterSet: false
    };
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.$store.dispatch('toggleFilterActivated');
      console.log(this.filtersActive);
      if (this.filtersActive == true) {
        this.fullTextSearchInput = '';
      }
      this.$emit('toggle');
    },
    clearFilter() {
      this.name = null;
      this.strasse = null;
      this.plz = null;
      this.ort = null;
      this.adrArt = null;
      this.customerGroup = null;
      this.kdBranche = null;
    },
    isFilterSet() {
      if (this.name != null || this.strasse != null || this.plz != null || this.ort != null || this.adrArt != null || this.customerGroup != null || this.kdBranche != null) {
        this.filterSet = true;
      } else {
        this.filterSet = false;
      }
    },
    resetFilter() {
      this.name = null;
      this.strasse = null;
      this.plz = null;
      this.ort = null;
      this.adrArt = null;
      this.kdBranche = null;
      this.customerGroup = null;
      this.$store.dispatch('resetFilter');
    },
    init_filter() {
      const storedFilter = this.getFilter;
      this.name = storedFilter.name;
      this.strasse = storedFilter.street;
      this.plz = storedFilter.postalCode;
      this.ort = storedFilter.city;
      addressService.getAddressTypes().then(data => {
        this.allAddressTypes = data;
        for (let i = 0; i < this.allAddressTypes.length; i++) {
          if (JSON.stringify(this.allAddressTypes[i]) === JSON.stringify(storedFilter.addressType)) {
            this.adrArt = storedFilter.addressType;
            break;
          }
        }
      });
      addressService.getFields().then(data => {
        this.branchen = data;
        for (let i = 0; i < this.branchen.length; i++) {
          if (JSON.stringify(this.branchen[i]) === JSON.stringify(storedFilter.field)) {
            this.kdBranche = storedFilter.field;
            break;
          }
        }
      });
      addressService.getCustomerGroups().then(data => {
        this.customerGroups = data;
        for (let i = 0; i < this.customerGroups.length; i++) {
          if (JSON.stringify(this.customerGroups[i]) === JSON.stringify(storedFilter.customerGroup)) {
            this.customerGroup = storedFilter.customerGroup;
            break;
          }
        }
      });
    },
    searchTrigger() {
      // Filtered search
      if (this.filtersActive) {
        this.removeQueryParam();
        this.searchAddressesFiltered();
      }
    },
    removeQueryParam() {
      this.$router.replace({
        query: {}
      }).catch(() => {});
    },
    searchAddressesFiltered() {
      this.$emit('searchTriggerd');
      var backendSearchFilter = {
        name: this.name,
        strasse: this.strasse,
        plz: this.plz,
        ort: this.ort,
        adrArt: this.adrArt,
        kdGruppe: this.customerGroup,
        branche: this.kdBranche
      };
      this.$store.dispatch('setFilter', {
        name: backendSearchFilter.name,
        street: backendSearchFilter.strasse,
        postalCode: backendSearchFilter.plz,
        city: backendSearchFilter.ort,
        addressType: backendSearchFilter.adrArt,
        customerGroup: backendSearchFilter.kdGruppe,
        field: backendSearchFilter.branche
      });
      addressService.searchAddressesFiltered(backendSearchFilter).then(data => {
        this.loading = false;
        this.$emit('searchSuccess', data);
      });
    }
  },
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter'])
  },
  mounted() {
    // Needs to get checked here unfortunately, because v-extension-panels seems buggy
    this.filtersActiveIndex = this.filtersActive ? 0 : 1;
    this.init_filter();
  }
};